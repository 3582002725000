import React from "react";
import ReactDOM from "react-dom";

import "./i18n";

import App from "./App";

import { Provider } from "react-redux";
import { configureStore } from "./redux/store";

// Google Analytics for Firebase
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// AWS Amplify
import { Amplify, Auth } from "aws-amplify";
import awsExports from "./aws-exports";
//Amplify.configure(awsExports);

import Hotjar from "@hotjar/browser";

import TagManager from "react-gtm-module";

const siteId = 3914925;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

Amplify.configure({
  ...awsExports,
  API: {
    graphql_headers: async () => {
      const session = await Auth.currentSession();
      return {
        Authorization: session.getIdToken().getJwtToken(),
      };
    },
  },
});

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCmlie5mLSMmOS2nUeANZf6240MRgzoVNY",
  authDomain: "line-up-dashboard.firebaseapp.com",
  projectId: "line-up-dashboard",
  storageBucket: "line-up-dashboard.appspot.com",
  messagingSenderId: "718021306305",
  appId: "1:718021306305:web:ea788827d6a5d0945cf140",
  measurementId: "G-KZJHR2ZKBV",
};

// Initialize Firebase & Firebase Analytics
const app = initializeApp(firebaseConfig);
const _ = getAnalytics(app);

TagManager.initialize({ gtmId: "GTM-NBKFWFM" });

ReactDOM.render(
  <Provider store={configureStore({})}>
    <App />
  </Provider>,
  document.getElementById("root")
);
