import React from "react";
import { Redirect } from "react-router-dom";
import { Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

// lazy load all the views

/*
Please keep the order like in the menu
Please keep order and naming theme like: Orders, OrderDetails, OrderForm
First letter is always capitalised
*/
const SpeedTestCreate = React.lazy(() =>
  import("../pages/speedTest/createSpeedtest")
);
const SpeedTest = React.lazy(() => import("../pages/speedTest/speedtest"));

// Main Menu
// Dashboard
const Dashboard = React.lazy(() => import("../pages/dashboard"));
const CompleteOverview = React.lazy(() =>
  import("../pages/completeOverview/CompleteOverview")
);
// Orders
const Orders = React.lazy(() => import("../pages/orders/Orders"));
const OrderDetails = React.lazy(() => import("../pages/orders/OrderDetails"));
/* const OrderForm = React.lazy(() => import("../pages/orders/OrderForm")); */
// Deliveries
const Delivery = React.lazy(() => import("../pages/deliveries/Delivery"));
const DeliveryDetails = React.lazy(() =>
  import("../pages/deliveries/DeliveryDetails")
);
const DeliveryForm = React.lazy(() =>
  import("../pages/deliveries/DeliveryForm")
);
const FreightRatesForm = React.lazy(() =>
  import("../pages/deliveries/FreightRatesForm")
);
// Shipments
const Shipments = React.lazy(() => import("../pages/shipments/Shipments"));
const ShipmentDetails = React.lazy(() =>
  import("../pages/shipments/ShipmentDetails")
);
const ShipmentForm = React.lazy(() =>
  import("../pages/shipments/ShipmentForm")
);
const SeaFreightRatesForm = React.lazy(() =>
  import("../pages/shipments/FreightRatesForm")
);
const Customs = React.lazy(() => import("../pages/customs/Customs"));
const CustomsDetails = React.lazy(() =>
  import("../pages/customs/CustomsDetails")
);
const CustomsForm = React.lazy(() => import("../pages/customs/CustomsForm"));

// Air Freights
const AirFreights = React.lazy(() =>
  import("../pages/airFreights/AirFreights")
);
const AirFreightDetails = React.lazy(() =>
  import("../pages/airFreights/AirFreightDetails")
);
const AirFreightForm = React.lazy(() =>
  import("../pages/airFreights/AirFreightForm")
);

// Purchase Orders
const PurchaseOrders = React.lazy(() =>
  import("../pages/purchaseOrders/PurchaseOrders")
);
const PurchaseOrderDetails = React.lazy(() =>
  import("../pages/purchaseOrders/PurchaseOrderDetails")
);
const PurchaseOrderForm = React.lazy(() =>
  import("../pages/purchaseOrders/PurchaseOrderForm")
);

// Product Requests
const ProductRequests = React.lazy(() =>
  import("../pages/productRequests/ProductRequests")
);
const ProductRequestDetails = React.lazy(() =>
  import("../pages/productRequests/ProductRequestDetails")
);
const ProductRequestForm = React.lazy(() =>
  import("../pages/productRequests/ProductRequestForm")
);
const CustomerRequests = React.lazy(() =>
  import("../pages/customerRequests/CustomerRequests")
);

const CustomerRequestForms = React.lazy(() =>
  import("../pages/customerRequests/CustomerRequestForm")
);
const SalesOffers = React.lazy(() =>
  import("../pages/salesOffers/SalesOffers")
);

const SalesOfferDetails = React.lazy(() =>
  import("../pages/salesOffers/SalesOffersDetails")
);

const SalesOfferForm = React.lazy(() =>
  import("../pages/salesOffers/SalesOffersForm")
);

const Notifications = React.lazy(() =>
  import("../pages/notifications/Notifications")
);

// Items
const Items = React.lazy(() => import("../pages/items/Items"));
const ItemDetails = React.lazy(() => import("../pages/items/ItemDetails"));
const ItemForm = React.lazy(() => import("../pages/items/ItemForm"));
const ItemsHeadForm = React.lazy(() => import("../pages/items/ItemsHeadForm"));
const ItemProperties = React.lazy(() =>
  import("../pages/items/ItemProperties")
);
const ItemPropertiesForm = React.lazy(() =>
  import("../pages/items/ItemPropertiesForm")
);
// Tasks
const Tasks = React.lazy(() => import("../pages/tasks/Tasks"));
//Customers
const Customers = React.lazy(() => import("../pages/customers/Customers"));
const CustomerDetails = React.lazy(() =>
  import("../pages/customers/CustomerDetails")
);
//Suppliers
const Suppliers = React.lazy(() => import("../pages/suppliers/Suppliers"));
const SupplierDetails = React.lazy(() =>
  import("../pages/suppliers/SupplierDetails")
);
const CustomerForm = React.lazy(() =>
  import("../pages/customers/CustomerForm")
);
const SuppliersForm = React.lazy(() =>
  import("../pages/suppliers/SupplierForm")
);
// Dropdown Menu
const Contact = React.lazy(() => import("../pages/contact/Contact"));
const Profile = React.lazy(() => import("../pages/profile/Profile"));
const UserManagement = React.lazy(() =>
  import("../pages/userManagement/UserManagement")
);
const UserRolesManagement = React.lazy(() =>
  import("../pages/userRolesManagement/UserRolesManagement")
);
const ImportValues = React.lazy(() =>
  import("../pages/importValues/ImportValues")
);
// auth
const Login = React.lazy(() => import("../pages/account/Login"));
const Logout = React.lazy(() => import("../pages/account/Logout"));
const Register = React.lazy(() => import("../pages/account/Register"));
const InvitationRegister = React.lazy(() =>
  import("../pages/account/InvitationRegister")
);
const Confirm = React.lazy(() => import("../pages/account/Confirm"));
const ForgetPassword = React.lazy(() =>
  import("../pages/account/ForgetPassword")
);
const SetNewPassword = React.lazy(() =>
  import("../pages/account/SetNewPassword")
);
const LockScreen = React.lazy(() => import("../pages/account/LockScreen"));
const VerifyEmail = React.lazy(() => import("../pages/account/VerifyEmail"));
// pages
const ErrorPageNotFound = React.lazy(() =>
  import("../pages/error/PageNotFound")
);
const ForbiddenErrorPage = React.lazy(() =>
  import("../pages/error/ForbiddenErrorPage")
);
const ErrorPageNotFoundAlt = React.lazy(() =>
  import("../pages/error/PageNotFoundAlt")
);
const ServerError = React.lazy(() => import("../pages/error/ServerError"));

// - other
const Maintenance = React.lazy(() => import("../pages/other/Maintenance"));
const Starter = React.lazy(() => import("../pages/other/Starter"));
const PreLoader = React.lazy(() => import("../pages/other/PreLoader/"));

/*
Please keep the order like in the menu
Please keep order and naming theme like:  orders, orderDetails, orderCreate, orderEdit 
First letter is always lower case
*/

// root routes
const rootRoute = {
  path: "/",
  exact: true,
  component: () => <Redirect to="/dashboard" />,
  route: PrivateRoute,
};
const speedTestCreate = {
  path: "/speedtestCreate",
  name: "SpeedTestCreate",
  route: PrivateRoute,
  component: SpeedTestCreate,
  header: "SpeedTestCreate",
};
const speedTest = {
  path: "/speedtest",
  name: "SpeedTest",
  route: PrivateRoute,
  component: SpeedTest,
  header: "SpeedTest",
};
// Mainmenu Objects
const dashboard = {
  path: "/dashboard",
  name: "Analytics",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: Dashboard,
  header: "Line Up - Dashboard",
};
const notifications = {
  path: "/notifications/",
  name: "Notifications",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: Notifications,
  header: "Line Up - Notifications",
};
const completeOverview = {
  path: "/completeOverview",
  name: "CompleteOverview",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: CompleteOverview,
  header: "Line Up - CompleteOverview",
};
const orders = {
  path: "/orders",
  exact: true,
  name: "Orders",
  route: PrivateRoute,
  roles: ["Admin", "superadmin", "superuser"],
  icon: "uil-calender",
  component: Orders,
  header: "Orders",
};
const orderDetails = {
  path: "/order/:orderID",
  exact: true,
  name: "Order Details",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: OrderDetails,
  header: "Line Up - Order Details",
};
/* const orderEdit = {
  path: "/order/edit/:orderID",
  name: "Order Edit",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: OrderForm,
  header: "Line Up - Order Edit",
}; */
const delivery = {
  path: "/deliveries",
  exact: true,
  name: "Delivery",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: Delivery,
  header: "Line Up - Delivery",
};
const deliveryDetails = {
  path: "/deliveryDetails/:deliveryId",
  name: "Delivery Details",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: DeliveryDetails,
  header: "Line Up - Delivery Details",
};
const deliveryCreate = {
  path: "/delivery/create",
  name: "Create Delivery",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: DeliveryForm,
  header: "Line Up - Create Delivery",
};
const deliveryEdit = {
  path: "/delivery/edit/:deliveryId",
  name: "Edit Delivery",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: DeliveryForm,
  header: "Line Up - Edit Delivery",
};
const salesOffers = {
  path: "/offers",
  exact: true,
  name: "Offers",
  route: PrivateRoute,
  roles: ["Admin", "superadmin", "superuser"],
  icon: "uil-calender",
  component: SalesOffers,
  header: "Offers",
};
const salesOffersDetails = {
  path: "/offer/:offerID",
  exact: true,
  name: "Offer Details",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: SalesOfferDetails,
  header: "Line Up - Offfer Details",
};
const salesOffersEdit = {
  path: "/offer/edit/:offerID",
  name: "Offer Edit",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: SalesOfferForm,
  header: "Line Up - Offer Edit",
};
const customerRequests = {
  path: "/requests",
  name: "Requests",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: CustomerRequests,
  header: "Line Up - Requests",
};
const customerRequestCreate = {
  path: "/request/create",
  name: "Request Create",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: CustomerRequestForms,
  header: "Line Up - Request Create",
};
const customerRequestEdit = {
  path: "/request/edit/:customerRequestID",
  name: "Request Edit ",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: CustomerRequestForms,
  header: "Line Up - Request Edit",
};
const freightRatesForm = {
  path: "/freightRates/create",
  name: "Freight Rates Create",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: FreightRatesForm,
  header: "Line Up - Freight Rates Create",
};
const shipments = {
  path: "/shipments",
  exact: true,
  name: "Shipments",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: Shipments,
  header: "Line Up - Shipments",
};
const shipmentDetails = {
  path: "/shipment/:shipmentID",
  exact: true,
  name: "Shipment Details",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: ShipmentDetails,
  header: "Line Up - Shipment Details",
};
const shipmentCreate = {
  path: "/shipments/create",
  name: "Shipment Create",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: ShipmentForm,
  header: "Line Up - Shipment Create",
};
const shipmentEdit = {
  path: "/shipment/edit/:shipmentId",
  name: "Shipment Edit",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: ShipmentForm,
  header: "Line Up - Shipment Edit",
};
const seaFreightRatesForm = {
  path: "/seaFreightRates/create",
  name: "Freight Rates Create",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: SeaFreightRatesForm,
  header: "Line Up - Freight Rates Create",
};
const customs = {
  path: "/customs",
  exact: true,
  name: "Customs",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: Customs,
  header: "Line Up - Customs",
};
const customsDetails = {
  path: "/customs/:customsID",
  exact: true,
  name: "Customs Details",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: CustomsDetails,
  header: "Line Up - Customs Details",
};
const customsCreate = {
  path: "/custom/create",
  name: "Customs Create",
  exact: true,
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: CustomsForm,
  header: "Line Up - Customs Create",
};

const airFreights = {
  path: "/airFreights",
  exact: true,
  name: "Air Freights",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: AirFreights,
  header: "Line Up - Air Freights",
};

const airFreightDetails = {
  path: "/airFreight/:airFreightID",
  exact: true,
  name: "Air Freight Details",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: AirFreightDetails,
  header: "Line Up - Air Freight Details",
};

const airFreightCreate = {
  path: "/airFreights/create",
  name: "Air Freights",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: AirFreightForm,
  header: "Line Up - Shipment Create",
};

const airFreightEdit = {
  path: "/airFreight/edit/:airFreightID",
  name: "Air Freights",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: AirFreightForm,
  header: "Line Up - Air Freight Edit",
};
const purchaseOrders = {
  path: "/purchaseOrders",
  exact: true,
  name: "Purchase Orders",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: PurchaseOrders,
  header: "Line Up - Purchase Orders",
};
const purchaseOrderDetails = {
  path: "/purchaseOrder/:purchaseOrderId",
  exact: true,
  name: "Purchase Order Details",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: PurchaseOrderDetails,
  header: "Line Up - Purchase Order Details",
};
const purchaseOrderCreate = {
  path: "/purchaseOrders/create",
  exact: true,
  name: "Purchase Order Create",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: PurchaseOrderForm,
  header: "Line Up - Purchase Order Create",
};
const purchaseOrderEdit = {
  path: "/purchaseOrder/edit/:purchaseOrderId",
  exact: true,
  name: "Purchase Order Edit",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: PurchaseOrderForm,
  header: "Line Up - Purchase Order Edit",
};
const productRequests = {
  path: "/productRequests",
  exact: true,
  name: "Product Requests",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: ProductRequests,
  header: "Line Up - Product Requests",
};
const productRequestDetails = {
  path: "/productRequest/:productRequestId",
  exact: true,
  name: "Product Request Details",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: ProductRequestDetails,
  header: "Line Up - Product Request Details",
};
const productRequestCreate = {
  path: "/productRequests/create",
  name: "Create Product Request",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: ProductRequestForm,
  header: "Line Up - Create Product Request",
};
const productRequestEdit = {
  path: "/productRequest/edit/:productRequestId",
  name: "Product Request Edit",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: ProductRequestForm,
  header: "Line Up - Product Request Edit",
};
const items = {
  path: "/items",
  exact: true,
  name: "Items",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: Items,
  header: "Line Up - Items",
};
const itemDetails = {
  path: "/item/:itemId",
  exact: true,
  name: "Item Details",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: ItemDetails,
  header: "Line Up - Item Details",
};
const itemCreate = {
  path: "/items/create",
  exact: true,
  name: "Item Create",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: ItemForm,
  header: "Line Up - Item Create",
};
const itemsHeadCreate = {
  path: "/items/head/create",
  exact: true,
  name: "Items Head Create",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: ItemsHeadForm,
  header: "Line Up - Items Head Create",
};
const itemsHeadEdit = {
  path: "/items/head/edit/:itemsHeadID",
  exact: true,
  name: "Items Head Edit",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: ItemsHeadForm,
  header: "Line Up - Items Head Edit",
};
const itemEdit = {
  path: "/item/edit/:itemID",
  exact: true,
  name: "Item Edit",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: ItemForm,
  header: "Line Up - Item Edit",
};
const itemsMultiEdit = {
  path: "/itemMultiedit",
  exact: true,
  name: "Item Edit",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: ItemForm,
  header: "Line Up - Items Multi Edit",
};
const itemProperties = {
  path: "/itemsProperties",
  exact: true,
  name: "Item Properties",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: ItemProperties,
  header: "Line Up - Items Properties",
};
const itemPropertiesCreate = {
  path: "/itemsProperties/create",
  exact: true,
  name: "Item Properties Create",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: ItemPropertiesForm,
  header: "Line Up - Items Properties Create",
};
const itemPropertiesEdit = {
  path: "/itemsProperties/edit/:itemPropertyId",
  exact: true,
  name: "Item Properties Edit",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: ItemPropertiesForm,
  header: "Line Up - Items Properties Edit",
};
const tasks = {
  path: "/tasks",
  name: "Task Board",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: Tasks,
  header: "Line Up - Tasks",
};
const customers = {
  path: "/customers",
  name: "Users Edit Page",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: Customers,
  header: "Line Up - Customers Overview Page",
};
const suppliers = {
  path: "/suppliers",
  name: "Users Edit Page",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: Suppliers,
  header: "Line Up - Suppliers Overview Page",
};

const customerDetails = {
  path: "/customer/:companyId",
  name: "Customer Edit Page",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: CustomerDetails,
  header: "Line Up - Customers Details Page",
};
const companySettings = {
  path: "/companySettings/:companyId",
  name: "Customer Edit Page",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: CustomerDetails,
  header: "Line Up - Customers Details Page",
};

const supplierDetails = {
  path: "/supplier/:companyId",
  name: "Supplier Edit Page",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: SupplierDetails,
  header: "Line Up - Suppliers Details Page",
};

const supplierCreate = {
  path: "/supplier/create",
  exact: true,
  name: "Supplier Create Page",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: SuppliersForm,
  header: "Line Up - Suppliers Create Page",
};

const customerCreate = {
  path: "/customer/create",
  exact: true,
  name: "Customer Create Page",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: CustomerForm,
  header: "Line Up - Customers Create Page",
};

const customerEdit = {
  path: "/customer/edit/:companyId",
  exact: true,
  name: "Customer Create Page",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: CustomerForm,
  header: "Line Up - Customers Create Page",
};

const supplierEdit = {
  path: "/supplier/edit/:companyId",
  exact: true,
  name: "Customer Create Page",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: SuppliersForm,
  header: "Line Up - Suppliers Create Page",
};

// Dropdown Menu Objects
const contact = {
  path: "/contact",
  name: "Contact",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: Contact,
  header: "Line Up - Contact",
};
const profile = {
  path: "/account",
  name: "Account",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: Profile,
  header: "Line Up - Account",
};
const userManagement = {
  path: "/users-management",
  name: "Users",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: UserManagement,
  header: "Line Up - Users",
};
const userRolesManagement = {
  path: "/userRolesManagement",
  exact: true,
  name: "UserRolesManagement",
  route: PrivateRoute,
  roles: ["Admin", "superadmin", "superuser"],
  icon: "uil-calender",
  component: UserRolesManagement,
  header: "User Roles Management",
};
const importValues = {
  path: "/importValues",
  name: "ImportValues",
  route: PrivateRoute,
  roles: ["Admin", "superadmin", "superuser"],
  icon: "uil-calender",
  component: ImportValues,
  header: "User Roles Management",
};
// others
const forbiddenPageLineUp = {
  path: "/error-permission-denied",
  name: "Forbidden Error Page",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: ForbiddenErrorPage,
  header: "Line Up - Forbidden Error Page",
};
const errorPageLineUp = {
  path: "*",
  name: "Error Page",
  route: PrivateRoute,
  roles: ["Admin", "superadmin"],
  icon: "uil-calender",
  component: ErrorPageNotFound,
  header: "Line Up - Error Page",
};

const appRoutes = [
  speedTestCreate,
  speedTest,
  // Main Menu
  dashboard,
  notifications,
  completeOverview,
  orders,
  orderDetails,
  /*   orderEdit, */
  customerRequests,
  customerRequestCreate,
  customerRequestEdit,
  delivery,
  deliveryDetails,
  deliveryCreate,
  deliveryEdit,
  freightRatesForm,
  shipments,
  shipmentDetails,
  shipmentCreate,
  shipmentEdit,
  seaFreightRatesForm,
  customs,
  customsDetails,
  customsCreate,
  salesOffers,
  salesOffersDetails,
  salesOffersEdit,
  purchaseOrders,
  purchaseOrderDetails,
  purchaseOrderCreate,
  purchaseOrderEdit,
  airFreights,
  airFreightDetails,
  airFreightCreate,
  airFreightEdit,
  productRequests,
  productRequestDetails,
  productRequestCreate,
  productRequestEdit,
  items,
  itemDetails,
  itemCreate,
  itemEdit,
  itemsMultiEdit,
  itemsHeadCreate,
  itemsHeadEdit,
  itemProperties,
  itemPropertiesCreate,
  itemPropertiesEdit,
  tasks,
  suppliers,
  customers,
  supplierCreate,
  customerCreate,
  supplierEdit,
  customerEdit,
  customerDetails,
  supplierDetails,
  // Dropdown Menu
  contact,
  profile,
  companySettings,
  userRolesManagement,
  importValues,
  userManagement,
  // others
  forbiddenPageLineUp,
  errorPageLineUp, // this one always has to be the last
];

// pages
const pageRoutes = {
  path: "/pages",
  name: "Pages",
  icon: "uil-copy-alt",
  header: "Custom",
  children: [
    {
      path: "/pages/error-404-alt",
      name: "Error - 404-alt",
      component: ErrorPageNotFoundAlt,
      route: PrivateRoute,
    },
    {
      path: "/pages/starter",
      name: "Starter Page",
      component: Starter,
      route: PrivateRoute,
    },
    {
      path: "/pages/preloader",
      name: "Starter Page",
      component: PreLoader,
      route: PrivateRoute,
    },
  ],
};

const otherPublicRoutes = [
  {
    path: "/maintenance",
    name: "Maintenance",
    component: Maintenance,
    route: Route,
  },
  {
    path: "/error-404",
    name: "Error - 404",
    component: ErrorPageNotFound,
    route: Route,
  },
  {
    path: "/error-500",
    name: "Error - 500",
    component: ServerError,
    route: Route,
  },
];

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
  let flatRoutes = [];

  routes = routes || [];
  routes.forEach((item) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// auth
const authRoutes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    route: Route,
  },
  {
    path: "/logout",
    name: "Logout",
    component: Logout,
    route: Route,
  },
  {
    path: "/register",
    exact: true,
    name: "Register",
    component: Register,
    route: Route,
  },
  // This including the inviationID has been manually added by MAX:
  {
    path: "/register/:invitationID",
    name: "InvitationRegister",
    component: InvitationRegister,
    route: Route,
  },
  {
    path: "/confirm",
    name: "Confirm",
    component: Confirm,
    route: Route,
  },
  {
    path: "/forget-password",
    name: "Forget Password",
    component: ForgetPassword,
    route: Route,
  },
  {
    path: "/new-password",
    name: "New Password",
    component: SetNewPassword,
    route: Route,
  },
  {
    path: "/lock-screen",
    name: "Lock Screen2",
    component: LockScreen,
    route: Route,
  },
  {
    path: "/verify-email",
    name: "Verify Email",
    component: VerifyEmail,
    route: Route,
  },
];

// All routes
const authProtectedRoutes = [rootRoute, ...appRoutes, pageRoutes];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);

export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
};
